import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as fromAuth from './auth.reducer';
import * as fromInsightCode from './insight-code.reducer';

export const featureKey = 'auth';

export interface AuthState {
  auth: fromAuth.AuthState;
  insightCode: fromInsightCode.InsightCodeState;
}

export const reducers: ActionReducerMap<AuthState> = {
  auth: fromAuth.authReducer,
  insightCode: fromInsightCode.InsightCodeReducer,
};

export const getAuthState = createFeatureSelector<AuthState>(featureKey);

export const metaReducers: MetaReducer<AuthState>[] = !environment.production ? [] : [];
