import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromInsightCode from '../reducers/insight-code.reducer';

export const getInsightCodeState = createSelector(fromFeature.getAuthState, (state: fromFeature.AuthState) => state.insightCode);

export const getInsightCode = createSelector(getInsightCodeState, fromInsightCode.getInsightCode);
export const getInsightCodeLoaded = createSelector(getInsightCodeState, fromInsightCode.getInsightCodeLoaded);
export const getInsightCodeLoading = createSelector(getInsightCodeState, fromInsightCode.getInsightCodeLoading);
export const getInsightCodeInitialized = createSelector(getInsightCodeState, fromInsightCode.getInsightCodeInitialized);
export const getInsightCodeInitializing = createSelector(getInsightCodeState, fromInsightCode.getInsightCodeInitializing);
export const getInsightCodeError = createSelector(getInsightCodeState, fromInsightCode.getInsightCodeError);
