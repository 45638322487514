import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginData, AuthData, RegisterData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  signIn(data: LoginData) {
    // return this.http.post<AuthData>(`https://rova-inzicht.fstr.io/api/my-rova/auth/login`, data);
    return this.http.post<AuthData>(`${this.BASE_URL}api/my-rova/auth/login`, data);
  }

  checkAuth() {
    return this.http.get<AuthData>(`${this.BASE_URL}api/my-rova/auth`, { withCredentials: true });
  }

  SignOut() {
    // console.log('signouturl', `${this.BASE_URL}api/my-rova/auth/logout`);
    return this.http.post<any>(`${this.BASE_URL}api/my-rova/auth/logout`, null);
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${this.BASE_URL}api/my-rova/auth/forgot-password?email=${email}`, null);
  }

  register(regData: RegisterData) {
    return this.http.post<AuthData>(`${this.BASE_URL}api/my-rova/auth/register`, regData);
  }

  resetCode(regData: RegisterData) {
    return this.http.post<AuthData>(`${this.BASE_URL}api/my-rova/auth/reset-code`, regData);
  }
}
