import { createAction, props } from '@ngrx/store';
import { InsightCode } from '../../models';

export const SetInsightCode = createAction(
  '[Auth] Set Insight Code',
  props<{ housenumber: number; addition: string; postalCode: string; code: InsightCode }>()
);
export const SetInsightCodeSuccess = createAction(
  '[Auth] Set Insight Code Success',
  props<{ code: InsightCode; preventSignIn?: boolean }>()
);
export const SetInsightCodeFail = createAction('[Auth] Set Insight Code Fail', props<{ error: any }>());

export const ClearInsightCode = createAction('[Auth] Clear Insight Code');

export const InitInsightCode = createAction('[Auth] Initialize InsightCode From Local Storage');
export const InitInsightCodeSuccess = createAction(
  '[Auth] Initialize InsightCode From Local Storage Success',
  props<{ code: InsightCode | null }>()
);
export const InitInsightCodeFail = createAction('[Auth] Initialize InsightCode From Local Storage Fail', props<{ error: any }>());

export const InsigtsCodeEmpty = createAction('[Auth] Initialize InsightCode (Empty)');
