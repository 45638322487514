import { createAction, props } from '@ngrx/store';
import { AuthData, LoginData, Profile, RegisterData } from '../../models';

export const CheckAuth = createAction('[Auth] Check Auth');
export const CheckAuthSuccess = createAction('[Auth] Check Auth Success', props<{ auth: AuthData }>());
export const CheckAuthFail = createAction('[Auth] Check Auth Fail', props<{ error: any }>());

export const SignIn = createAction('[Auth] Sign In', props<{ loginData: LoginData; noRedirect?: boolean; rememberCode?: boolean }>());
export const SignInSuccess = createAction(
  '[Auth] Sign In Success',
  props<{ auth: AuthData; loginData?: LoginData; noRedirect?: boolean; rememberCode?: boolean }>()
);
export const SignInFail = createAction('[Auth] Sign In Fail', props<{ error: any }>());

export const SignOut = createAction('[Auth] Sign Out', props<{ noRedirect?: boolean }>());
export const SignOutSuccess = createAction('[Auth] Sign Out Success', props<{ result: { success: boolean }; noRedirect?: boolean }>());
export const SignOutFail = createAction('[Auth] Sign Out Fail', props<{ error: any }>());

export const Register = createAction('[Auth] Register', props<{ registerData: RegisterData }>());
export const RegisterSuccess = createAction('[Auth] Register Success', props<{ result: AuthData }>());
export const RegisterFail = createAction('[Auth] Register Fail', props<{ error: any }>());

export const ResetCode = createAction('[Auth] Reset Code', props<{ registerData: RegisterData }>());
export const ResetCodeSuccess = createAction('[Auth] Reset Code Success', props<{ result: AuthData }>());
export const ResetCodeFail = createAction('[Auth] Reset Code Fail', props<{ error: any }>());

export const RemoveErrors = createAction('[Auth] Remove errors');
