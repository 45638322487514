import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAuth from '../reducers/auth.reducer';

export const getContentPageState = createSelector(fromFeature.getAuthState, (state: fromFeature.AuthState) => state.auth);

export const getAuthChecked = createSelector(getContentPageState, fromAuth.getAuthChecked);
export const getAuthChecking = createSelector(getContentPageState, fromAuth.getAuthChecking);
export const getAuthSignedIn = createSelector(getContentPageState, fromAuth.getAuthSignedIn);
export const getAuthSigningIn = createSelector(getContentPageState, fromAuth.getAuthSigningIn);
export const getAuthUser = createSelector(getContentPageState, fromAuth.getAuthUser);
export const getAuthError = createSelector(getContentPageState, fromAuth.getAuthError);

export const getUser = createSelector(getContentPageState, fromAuth.getUser);

export const getRegistering = createSelector(getContentPageState, fromAuth.getRegistering);
export const getRegisterSuccess = createSelector(getContentPageState, fromAuth.getRegisterSuccess);
export const getRegisterError = createSelector(getContentPageState, fromAuth.getRegisterError);

export const getResetting = createSelector(getContentPageState, fromAuth.getResetting);
export const getResetSuccess = createSelector(getContentPageState, fromAuth.getResetSuccess);
export const getResetError = createSelector(getContentPageState, fromAuth.getResetError);
