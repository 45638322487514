import { createReducer, on } from '@ngrx/store';
import { InsightCode } from '../../models';
import * as actions from '../actions/insight-code.action';

export interface InsightCodeState {
  code: InsightCode | null;
  loaded: boolean;
  loading: boolean;
  initializing: boolean;
  initialized: boolean;
  error?: any;
}

export const initialState: InsightCodeState = {
  code: null,
  loaded: false,
  loading: false,
  initialized: false,
  initializing: false,
};

export const InsightCodeReducer = createReducer(
  initialState,
  on(actions.SetInsightCode, (state: InsightCodeState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(actions.SetInsightCodeSuccess, (state: InsightCodeState, { code }) => {
    return {
      ...state,
      code,
      loading: false,
      loaded: true,
      error: undefined,
    };
  }),
  on(actions.SetInsightCodeFail, (state: InsightCodeState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.InitInsightCode, (state: InsightCodeState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(actions.InitInsightCodeSuccess, (state: InsightCodeState, { code }) => {
    return {
      ...state,
      code,
      loaded: code !== null,
      initializing: false,
      initialized: true,
    };
  }),
  on(actions.SetInsightCodeFail, (state: InsightCodeState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
      initializing: false,
      initialized: true,
    };
  }),
  on(actions.ClearInsightCode, (state: InsightCodeState) => {
    return {
      ...state,
      code: null,
      loaded: false,
      loading: false,
      initialized: true,
      initializing: false,
      error: null,
    };
  })
);

export const getInsightCode = (state: InsightCodeState) => state.code;
export const getInsightCodeLoading = (state: InsightCodeState) => state.loading;
export const getInsightCodeLoaded = (state: InsightCodeState) => state.loaded;
export const getInsightCodeInitializing = (state: InsightCodeState) => state.initializing;
export const getInsightCodeInitialized = (state: InsightCodeState) => state.initialized;
export const getInsightCodeError = (state: InsightCodeState) => state.error;
