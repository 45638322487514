import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import * as authActions from '../actions/auth.action';
import { switchMap, map, catchError, tap, take, flatMap, mergeMap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { Store } from '@ngrx/store';
import { getRouterState, Go } from '../../../store/index';
import { SetAddress } from 'src/app/addresses/store';
import { ClearCompletedProfile } from 'src/app/settings/store';
import { SetInsightCode, SetInsightCodeSuccess } from '../actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store
  ) {}

  checkAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.CheckAuth),
      switchMap(() =>
        this.authService.checkAuth().pipe(
          map(auth => authActions.CheckAuthSuccess({ auth })),
          catchError(error => of(authActions.CheckAuthFail({ error })))
        )
      )
    );
  });

  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignIn),
      switchMap(({ loginData, noRedirect, rememberCode }) =>
        this.authService.signIn(loginData).pipe(
          map(auth => authActions.SignInSuccess({ auth, loginData, noRedirect, rememberCode })),
          catchError(error => of(authActions.SignInFail({ error })))
        )
      )
    );
  });

  signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignInSuccess),
      switchMap(({ noRedirect }) =>
        this.store.select(getRouterState).pipe(
          take(1),
          mergeMap(({ state }) => {
            return noRedirect ? EMPTY : of(Go({ path: [state.queryParams['returnUrl'] || '/dashboard'] }));
          })
        )
      )
    );
  });

  signInSuccessSaveCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignInSuccess),
      switchMap(({ loginData, rememberCode }) =>
        rememberCode && loginData?.code ? of(SetInsightCodeSuccess({ code: loginData?.code, preventSignIn: true })) : EMPTY
      )
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.SignOut),
      switchMap(({ noRedirect }) =>
        this.authService.SignOut().pipe(
          map(auth => {
            localStorage.removeItem('ADDRESS');
            localStorage.removeItem('WasteCardNumber');

            return authActions.SignOutSuccess({ result: auth, noRedirect });
          }),
          catchError(error => of(authActions.SignOutFail({ error })))
        )
      )
    );
  });

  signOutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.SignOutSuccess),
        tap(({ noRedirect }) => {
          if (noRedirect) {
            return;
          }
          window.location.href = '/mijn-rova/aanmelden/inloggen/';
        })
      );
    },
    { dispatch: false }
  );

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.Register),
      switchMap(({ registerData }) =>
        this.authService.register(registerData).pipe(
          map(result => authActions.RegisterSuccess({ result: result })),
          catchError(error => of(authActions.RegisterFail({ error })))
        )
      )
    );
  });

  resetCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.ResetCode),
      switchMap(({ registerData }) =>
        this.authService.resetCode(registerData).pipe(
          map(result => authActions.ResetCodeSuccess({ result: result })),
          catchError(error => of(authActions.ResetCodeFail({ error })))
        )
      )
    );
  });
}
