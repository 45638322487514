import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import * as actions from '../actions/insight-code.action';
import { switchMap, map, catchError, tap, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Go, getRouterState } from 'src/app/store';
import { AuthService } from '../../services';
import { getAddress } from 'src/app/addresses';
import { getAuthSignedIn } from '../selectors';
import { SignIn, SignOut } from '../actions';

@Injectable()
export class InsightCodeEffects {
  private STORAGE_KEY = 'ROVA:InsightCode';

  constructor(
    private actions$: Actions,
    private service: AuthService,
    private store: Store
  ) {}

  SetInsightCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.SetInsightCode),
      map(({ code }) => actions.SetInsightCodeSuccess({ code }))
    );
  });

  SetInsightCodeSuccessSignin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.SetInsightCodeSuccess),
      withLatestFrom(this.store.select(getAddress)),
      switchMap(([{ code, preventSignIn }, address]) =>
        !preventSignIn
          ? of(
              SignIn({
                loginData: {
                  houseNumber: +address!.houseNumber,
                  houseNumberAddition: address!.addition || '',
                  postalCode: address!.postalCodeFull,
                  isPersistent: true,
                  signedIn: false,
                  code,
                },
                noRedirect: true,
              })
            )
          : EMPTY
      )
    );
  });

  SetInsightCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.SetInsightCodeSuccess),
        switchMap(InsightCode =>
          this.store.select(getRouterState).pipe(
            take(1),
            tap(({}) => {
              localStorage.setItem(this.STORAGE_KEY, InsightCode.code);
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  ClearInsightCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.ClearInsightCode),
      switchMap(() =>
        this.store.select(getRouterState).pipe(
          take(1),
          tap(({}) => {
            localStorage.removeItem(this.STORAGE_KEY);
          }),
          switchMap(() => of(SignOut({ noRedirect: true })))
        )
      )
    );
  });

  InitInsightCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitInsightCode),
      map(() => {
        const storedInsightCode = localStorage.getItem(this.STORAGE_KEY);
        if (storedInsightCode) {
          return actions.InitInsightCodeSuccess({ code: storedInsightCode });
        }
        return actions.InitInsightCodeSuccess({ code: null });
      }),
      catchError(error => of(actions.InitInsightCodeFail({ error })))
    );
  });

  InitInsightCodeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitInsightCodeSuccess),
      withLatestFrom(this.store.select(getAddress), this.store.select(getAuthSignedIn)),
      map(([{ code }, address, signedIn]) => {
        if (code && address && !signedIn) {
          return SignIn({
            loginData: {
              houseNumber: +address.houseNumber,
              houseNumberAddition: address.addition || '',
              postalCode: address.postalCodeFull,
              isPersistent: true,
              signedIn: false,
              code,
            },
            noRedirect: true,
          });
        }
        return actions.InsigtsCodeEmpty();
      })
    );
  });
}
